<template>
  <b-row>
    <b-col cols="12">
      <validation-provider
        #default="{ errors }"
        :name="validacaoNome"
        :rules="required ? 'required' : ''"
      >
        <b-input-group
          class="input-group-merge"
          :class="errors.length > 0 ? 'is-invalid' : null"
        >
          <b-input
            :id="id"
            v-model="valueInner"
            :state="errors.length > 0 ? false : null"
            class="form-control-merge"
            :type="passwordFieldType"
            :autocomplete="autoCompleteSenha"
            placeholder="*******"
            @input="sendToParent"
            @blur.stop="blurFunction"
            @change="changeFunction"
          />
          <b-input-group-append
            v-if="mostrarSenha"
            is-text
          >
            <feather-icon
              class="cursor-pointer"
              :icon="passwordToggleIcon"
              @click="togglePasswordVisibility"
            />
          </b-input-group-append>
        </b-input-group>
        <small
          class="text-danger"
          :style="validacaoDireita ? 'float:right;' : ''"
        >{{ mostrarErro(errors[0]) }}</small>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>

import { ValidationProvider } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'SenhaInput',
  components: {
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  props: {
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    mostrarSenha: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    validacaoNome: {
      type: String,
      default: 'Senha',
    },
    validacaoDireita: {
      type: Boolean,
      default: false,
    },
    autoCompleteSenha: {
      type: String,
      default: '',
    },
    blurFunction: {
      type: Function,
      default: () => {},
    },
    changeFunction: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      valueInner: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueInner = value
      }
    },
  },
  created() {
    this.valueInner = this.value
  },
  methods: {
    sendToParent() {
      this.$emit('input', this.valueInner)
    },
    mostrarErro(erro) {
      if (erro !== undefined && erro.includes('field is required')) {
        return `O campo ${this.validacaoNome} é obrigatório`
      }
      return erro
    },
  },
}
</script>
